import { Box, Modal, Typography } from "@mui/material";

export default function Navbar(props) {
    const { open, onClose } = props;

    return (
        <Modal
            open={open}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, .8)',
                },
            }}
        >
            <Box sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backdropFilter: "blur(8px)",
                flexDirection: "column",
                gap: "70px",
            }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                }}>
                    < Typography variant="h6">Agustin Cabrera</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    gap: "16px",
                }}>

                    <Typography variant="h4">Inicio</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    gap: "16px"
                }}>
                    <Typography sx={{
                        fontSize: "1em",
                    }}
                        onClick={() => window.open("https://www.linkedin.com/in/agust%C3%ADn-cabrera-8372ba302?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app")}
                    >Linkedin</Typography>
                    <Typography sx={{
                        fontSize: "1em",
                    }}
                        onClick={() => {
                            window.location.href = "mailto:agustinc162@gmail.com";
                        }}
                    >
                        Email
                    </Typography>
                </Box>

            </Box>
        </Modal>


    )
}