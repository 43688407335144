import { Box } from "@mui/material";
import Navbar from "./Navbar";
import { useState } from "react";

export default function MenuHamburguesa() {
    const [open, setOpen] = useState(false);


    return (
        <div>
            <Box sx={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                position: "fixed",
                top: "20px",
                right: "20px",
                backdropFilter: open ? "none" : "blur(8px)",
                zIndex: 10000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
            }}
                onClick={() => setOpen(!open)}
            >
                <div id="nav-icon3" className={open ? "open" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </Box>
            <Navbar open={open} onClose={() => setOpen(false)} />

        </div >
    );
}