import { Box, Fade, Typography, colors } from "@mui/material";
import MenuHamburguesa from "./components/MenuHamburguesa";
import Foto1 from "./../../assets/foto1.jpg";
import Servicio1 from "./../../assets/servicio1.jpeg";
import Servicio2 from "./../../assets/servicio2.jpeg";
import Servicio3 from "./../../assets/servicio3.jpeg";


import React from 'react';
import useTypewriter from 'react-typewriter-hook';

function MagicWriter() {
    const MagicColorText = useTypewriter(' con la experiencia de un asesor tecnologico y contable.', 300);
    return <div style={{ fontWeight: 'bold', fontSize: '30px' }}><span style={{ color: '#000000' }}>Potencia tu negocio </span><span style={{ color: '#888888' }}>{MagicColorText}</span></div>
}




export default function Home() {


    const servicios = [
        {
            nombre: "Asesoramiento tecnológico",
            imagen: Servicio3,
            mensajeWpp: "Hola, me gustaría recibir más información sobre el servicio de asesoramiento tecnológico",
        }, ,
        {
            nombre: "Auditoría e informes",
            imagen: Servicio1,
            mensajeWpp: "Hola, me gustaría recibir más información sobre el servicio de auditoría e informes",
        },
        {
            nombre: "Contabilidad y finanzas",
            imagen: Servicio2,
            mensajeWpp: "Hola, me gustaría recibir más información sobre el servicio de contabilidad y finanzas",
        },
    ]

    function TarjetaServicio(servicio) {
        return <div style={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            borderRadius: "30px",
            maxWidth: "400px",
        }}>
            <img src={servicio.imagen} alt={servicio.nombre} style={{
                width: "100%",
                height: "100%",
                display: "block",
            }} />
            <div style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                height: "30%",
                width: "100%",
                background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
            }}>
                <div style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    boxSizing: "border-box",
                    padding: "0px 30px 30px 30px",
                }}>
                    <h3 style={{
                        color: "white",
                        width: "60%",
                        fontWeight: "bold",
                        fontSize: "24px",
                        height: "auto",
                        margin: 0,
                    }}>
                        {servicio.nombre}
                    </h3>
                    <Box sx={{
                        cursor: "pointer",
                        background: "white",
                        fontSize: "18px",
                        padding: "10px 20px",
                        borderRadius: "35px",

                        '&:hover': {
                            background: "#a0a0a0",
                        }
                    }}
                        onClick={() => window.open(`https://wa.me/5493534764808?text=${servicio.mensajeWpp}`)}
                    >
                        Ver Más
                    </Box>
                </div>
            </div>
        </div >
    }

    return (
        <div style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "100vw",
            overflow: "hidden",
        }}>
            <header style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "80px 0 0 0",
                alignItems: "center",
                height: "90vh",
            }}>
                <div style={{
                    height: "150px",
                    width: "100%",
                }}>
                    <MagicWriter />
                </div>
                <Fade in={true} timeout={2000}>
                    <img src={Foto1} alt="Foto 1" style={{
                        width: "100%",
                        borderRadius: "30px",
                        marginTop: "20px",
                    }} />
                </Fade>
            </header>
            <section style={{
                width: "100%",
                paddingBottom: "100px",
            }}>
                <Typography sx={{
                    fontWeight: "bold",
                    fontSize: "30px",
                }}>
                    Descubre la nueva era de la contabilidad
                </Typography>
                <Typography sx={{
                    color: colors.grey[700],
                    fontSize: "20px",
                }}>
                    Nustro objetivo es redefinir la contabilidad con tecnología de vanguardia. Ofrecemos soluciones contables eficientes, precisas y personalizadas, para que tu negocio pueda concentrarse en lo que realmente importa, crecer y prosperar.
                </Typography>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "100px",
                    width: "100%",
                    paddingTop: "80px",
                    alignItems: "center",
                }}>
                    {servicios.map(servicio => <TarjetaServicio {...servicio} />)}
                </div>
            </section>
            <MenuHamburguesa />
        </div >
    );
}